
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .touch-focused", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".touch-focused").not($o).not($o.closest(".touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
    });

    $(document).on("click", ".touchevents .touch-focused a", function (e) {
        var $tf = $(this).closest(".touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", ".touchevents *", function (e) {
        if (!$(e.target).closest(".touch-focused").length) {
            $(".touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".no-click", function (e) {
        e.preventDefault();
    });

}



/*

Responsive design helpers

 */

function responsive_type() {
    if (!$(".respon-meter").length)
    {
        $("body").append("<div class='respon-meter'></div>");
    }
    return $(".respon-meter").css("z-index");
}

function responsive_init() {
    responsive_update();
}

function responsive_update() {
    $("body").addClass('notransition');

    if (typeof force === "undefined") force = false;
    if ($("body").data("responsive_type") != responsive_type() || force)
    {
        $("body").data("responsive_type", responsive_type());
    }

    $("[data-place]").each(function(){
        var places = $(this).data("place");
        var breakpoints = Object.keys(places).map(function(value) {
            return parseInt(value);
        }).sort(function(a,b) {
            return a - b;
        }).reverse();
        for (i in breakpoints) {
            if (responsive_type() > breakpoints[i]) {
                if ($(places[breakpoints[i]]).length)  {
                    $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                }
                break;
            }
        }
    });

    $("body")[0].offsetHeight;
    $("body").removeClass('notransition');
}


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o) {
    if (typeof $o === "undefined") $o = $(".img-to-bg");
    $o.each(function () {
        if ($(this).find("> img").length && $(this).find("> img").attr("src")) {
            $(this).css("background-image", "url('" + $(this).find("> img").attr("src") + "')");
            $(this).addClass("img-to-bg--inited");
        }
    });
}


/*

 Lazy Loading Helpers

 */

function lazy_load_init() {
    lazy_load();
    $(window).on("load scroll resize orientationchange", function () {
        lazy_load();
    });
}

function lazy_load() {
    $("[data-lazy-src]").not(".lazy-load-inited").each(function () {
        if ($(this).parent().is(":visible")) {
            if ($(this).data("lazy-src-onload") || ($(this).offset().top || $(this).parent().offset().top) - $(window).height() < $(window).scrollTop() + $(window).height()) {
                lazy_load_change($(this));
            }
        }
    });
}

function lazy_load_change($img) {
    var src = $img.data("lazy-src");
    if (typeof src === "object") {
        var breakpoints = Object.keys(src).map(function(value) {
            return parseInt(value);
        }).sort(function(a,b) {
            return a - b;
        }).reverse();
        for (i in breakpoints) {
            if (responsive_type() > breakpoints[i]) {
                $img.attr("data-lazy-src-breakpoint", breakpoints[i]);
                src = src[breakpoints[i]];
                break;
            }
        }
    }
    $img.attr("src", src);
    $img.one("load", function () {
        var $this = $(this);
        img_to_bg($this.closest(".img-to-bg"));
    });
    $img.addClass("lazy-load-inited");
}


/*

Scroll to needed objects

 */

function goto_init()
{
    $(document).on("click", ".js-goto", function (e) {
        var $o = $($(this).attr("href"));
        if ($o.length) {
            e.preventDefault();
            var $this = $(this);
            if ($this.closest(".goto-list").length)
            {
                $this.closest(".goto-list").find("li").removeClass("active");
                $this.closest("li").addClass("active");
                tabs_update_pointer($this.closest(".tabs"));
            }
            if ($this.closest(".goto-hash-change").length)
            {
                location_hash_update($this.attr("href"));
            }
            goto_object($o);
        }
    });
}

function goto_object($o)
{
    if ($o.length)
    {
        $("body").addClass("scroll-animated");
        var offset = $o.offset().top;
        offset -= $(".js-goto-offset").first().outerHeight();
        if ($o.data("goto-offset-element")) {
            offset -= $($o.data("goto-offset-element")).outerHeight();
        }
        $("html, body").stop(true, true).animate({scrollTop: offset}, 500, function(){
            $("body").removeClass("scroll-animated");
        });
    }
}

function activate_goto_link($obj)
{
    $obj.addClass("active").siblings().removeClass("active")
        .end().parent("li").addClass("active").siblings().removeClass("active");
    $obj.trigger("click-tabs-fixed-center");
}





/*

Expanded Blocks Functionality

 */

function expand_it_init()
{
    expand_it_init_prepare();
    $(document).ajaxStop(function () {
        expand_it_init_prepare();
    });
    $(window).on("load resize", function () {
        expand_it_init_prepare(null, true);
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        e.preventDefault();
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.filter(".expand-it-container").length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        }
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        expand_it_trigger($(this), $o, e.type === "expand-it");
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        if ($(e.target).hasClass("expand-it-container") && !$(e.target).hasClass("expand-it-container-overflow-hidden") && $(e.target).hasClass("before-transition"))
        {
            var height_default = 0;
            var current_height = parseInt($(this).css("max-height"), 10);
            if ($(this).find(".expand-it-container-height-default").length)
            {
                height_default = $(this).find(".expand-it-container-height-default").height();
            }
            if (current_height > height_default)
            {
                $(this).addClass("overflow-visible");
            }
            else
            {
                $(this).removeClass("overflow-visible");
            }
        }
        if ($(e.target).hasClass("expand-it-container") && $(e.target).hasClass("expand-it-container-max-height-auto") && $(e.target).hasClass("before-transition"))
        {
            var id = $(this).attr("id");
            setTimeout(function(){
                removeCSSRule("rule-"+id);
            }, 300);
        }
        expand_it_init_prepare($(this).parents('.expand-it-container'), true);
        $(e.target).removeClass("before-transition");
        $(e.target).trigger("expandAfter");
    });

    if (location.hash && location.hash.substr(0, 1).match(/\w/i))
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id)
    {
        id = "id"+(new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("no-transition-only-this");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden"))
    {
        $o.removeClass("overflow-visible");
    }
    if ($o.hasClass("active"))
    {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("no-transition-only-this");
    if (!$o.hasClass("active") && !$("#rule-"+id).length) {
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active");
    $(".expand-it.active[href='#"+$o.attr("id")+"']").not($handler).toggleClass("active");

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft)
    {
        $o.trigger("expandBefore");
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
            $(".expand-it.active[href='#"+$handler.attr("id")+"']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
        {
            $wrapper.siblings(".expand-it-wrapper").each(function(){
                var $this = $(this).find(".expand-it-container").first();
                if ($(this).find(".expand-it").length)
                {
                    $(this).find(".expand-it.active").trigger("expand-it");
                }
                else
                {
                    var $this_other = $(".expand-it.active[href='#"+$this.attr("id")+"']");
                    if ($this_other.length) {
                        $this_other.trigger("expand-it");
                    }
                    else {
                        var $this_other = $(this).find('.expand-it-pseudo')/*.filter(function() {
                            return $(this).parent($this).length === 0;
                        })*/;
                        if ($this_other.length) {
                            expand_it_trigger($this_other, $this, true);
                        }
                    }
                    if ($this_other.length) {
                        $this_other.removeClass("active");
                    }
                }
                $this.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
            if ($wrapper.hasClass("active")) {
                setTimeout(function(){
                    if ($wrapper.offset().top < $(window).scrollTop() + ($(".header--fixed").outerHeight() || 0)) {
                        goto_object($wrapper);
                    }
                }, 550);
            }
        }
        if (($o.hasClass("active") || $o.hasClass("expand-it-container--scroll-to-always")) && $handler.hasClass("expand-it-scroll-to"))
        {
            goto_object($o, 900, 'easeInOutQuad');
        }
        if ($handler.hasClass("expand-it-hash-change"))
        {
            if (is_active)
            {
                if ($handler.attr("href"))
                {
                    location_hash_update($handler.attr("href"));
                }
                else if ($wrapper.attr("id"))
                {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            }
            else
            {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id"))
                {
                    location_hash_update("#"+$tabpane.attr("id"));
                }
                else
                {
                    location_hash_remove();
                }
            }
        }
        $o.trigger('expand-it-'+((is_active)?'open':'close'));
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    $c.each(function(){
        var $o = $(this);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        height = $o.find(".expand-it-inner").outerHeight(true);
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
        $o.addClass("expand-it-container-prepared");
    });
}



/*

Hide/Show blocks

 */


function toggle_element_init()
{
    $(document).on("click click-pseudo change", ".toggle-element, .show-element, .hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        var class_name = "hidden";
        if ($(this).data("toggle-class")) {
            class_name = $(this).data("toggle-class");
        }
        var element_class_name = "active";
        if ($(this).data("toggle-element-class")) {
            element_class_name = $(this).data("toggle-element-class");
        }
        var change_label = false;
        var action_scroll_pos = $(this).offset().top - $(window).scrollTop();
        if ($(this).hasClass("toggle-element")) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            }
            else {
                $(this).toggleClass(element_class_name);
                $o.toggleClass(class_name);
            }
            if ($o.hasClass(class_name)) {
                if ($(this).offset().top < $(window).scrollTop()) {
                    $(window).scrollTop($(this).offset().top - action_scroll_pos);
                }
            }
            $o.trigger("toggle-element");
        }
        if ($(this).hasClass("show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, $(this).data("toggle-reverse"));
            $o.trigger("show-element");
        }
        if ($(this).hasClass("hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.trigger("hide-element");
        }
        if (change_label && $(this).data("toggle-change-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-toggle-change-label"));
            $(this).attr("data-toggle-change-label", label);
        }
        var $siblings = $(this).siblings();
        if ($(this).data("toggle-siblings-selector")) {
            $siblings = $(this).closest($(this).data("toggle-siblings-selector")).siblings();
        }
        if ($(this).data("toggle-current-selector")) {
            $siblings = $siblings.find($(this).data("toggle-current-selector"));
        }
        $siblings.removeClass(element_class_name);
    });
}


/*

Actions on Scroll

 */

function scroll_animations_init() {
    scroll_animations();
}

function scroll_animations() {

    var st = $(window).scrollTop();

    body_scrolled();
}

function header_scrolled() {

    var $w = $(window);

    var $h = $(".header");
    var $hr = $(".header-replace");
    $("body").toggleClass("header-fixed", $w.scrollTop() >= $hr.offset().top);


}

function body_scrolled() {
    var scrolled_condition = $(window).scrollTop() > 0;
    if (scrolled_condition) {
        if (!$("body").hasClass("scrolled")) $("body").addClass("scrolled");
    }
    else {
        if ($("body").hasClass("scrolled")) $("body").removeClass("scrolled");
    }
}