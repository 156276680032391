$(function () {
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();
    $("body").addClass("ready");

    responsive_init();
    init_event_handlers();

    bind_widgets();
    $(document).ajaxStop(function () {
        bind_widgets();
    });

    scroll_animations_init();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").addClass("win-loaded").removeClass("win-not-loaded");

    $(document).imagesLoaded(function () {
        te = (new Date()).getTime();
        console.log("Images Load Time: ", te - ts);
        setTimeout(function () {
            $("body").addClass("loaded").removeClass("not-loaded");
        }, 10);
    });

    responsive_update();

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    if (typeof $("body").data("scrollval") === "undefined") $("body").data("scrollval", 0);

    scroll_animations();

    $("body").data("scrollval", $(window).scrollTop());
});

$(window).on("resize orientationchange", function (e) {
    responsive_update();
    scroll_animations();
});

function init_event_handlers() {

    lazy_load_init();
    fix_touch_hovers();
    click_touch_init();
    textfield_init();
    goto_init();
    toggle_element_init();
    expand_it_init();
    tabs_init();

    // HEADER and MENU

    $(document).on("click", ".js-menu-switcher", function (e) {
        $("body").toggleClass("menu-overlay-active");
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        $("body").removeClass("menu-overlay-active");
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".menu-overlay, .js-menu-switcher").length) {
            $("body").removeClass("menu-overlay-active");
        }
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".hsearch, .js-hsearch-switcher").length) {
            $("body").removeClass("hsearch-active");
        }
    });

    $(document).on("click", ".js-intgb-trigger", function (e) {
        e.preventDefault();
        $o = $($(this).attr("href"));
        if ($o.length) {
            $root = $o.closest('.intgb-root');
            $root.find('.intgb-container').removeClass('active');
            $root.find('.js-intgb-trigger').removeClass('active');
            $o.addClass('active');
            $parents = $o.parents('.intgb-container');
            if ($parents.length) {
                $parents.addClass('active').each(function(){
                    var $olink = $('a[href="#'+$(this).attr("id")+'"]');
                    $olink.addClass('active');
                });
            }
            $(this).addClass('active');
        }
    });

    $(document).on("click", ".js-video-play", function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        var $video = $(this).siblings('video');
        if ($video.length) {
            $video.toggleClass('active').attr('controls','');
            $video[0].play();
        }
    });



    $(window).on("scroll resize load header-update", function(e){
        //header_scrolled();
    });

    $(window).on("resize load", function(e){
        var $mc = $(".map-contacts iframe");
        if ($mc.length) {
            $mc.height(Math.max(400, $("body").outerHeight() - $mc.offset().top - $(".footer").outerHeight()));
        }
    });



}


function bind_widgets() {
    img_to_bg();
    slider_init();
    fancybox_init();
}