
function location_hash_update(hash, loc) {
    if (history.replaceState) {
        history.replaceState({
            time: (new Date()).getTime(),
            hash: hash
        }, null, ((typeof loc !== "undefined" && loc) ? loc : "") + ((typeof hash !== "undefined" && hash) ? hash : ""));
    }
    else {
        location.hash = hash;
    }
}

function location_hash_remove() {
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history) {
        history.pushState("", document.title, loc.pathname + loc.search);
    }
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

function touchclick() {
    if ($(".no-touchevents").length) return "click";
    else return "touchstart";
}

var addCSSRule = function (sheet_id, rules) {
    $("#" + sheet_id).remove();
    $("<style type='text/css' id='" + sheet_id + "'>" + rules + "</style>").appendTo("head");
};

var removeCSSRule = function (sheet_id) {
    $("#" + sheet_id).remove();
};

var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();
/*
var forceRedraw = function(element){

    if (!element) { return; }

    var n = document.createTextNode(' ');
    var disp = element.style.display;  // don't worry about previous display style

    element.appendChild(n);
    element.style.display = 'none';

    setTimeout(function(){
        element.style.display = disp;
        n.parentNode.removeChild(n);
    },20); // you can play with this timeout to make it as short as possible
}*/
var forceRedraw = function( $el ) {
    return ( $el && $el.length && $el[0].offsetHeight );
};

$.fn.hasScrollBar = function() {
    return this.get(0).scrollHeight > this.outerHeight();
}

$.fn.hasHorzScrollbar = function() {
    return this.get(0).scrollWidth > this.width();
}

function fix_touch_hovers()
{
    if (Modernizr.touchevents)
    {
        try
        {
            var count = 0;
            var ignore = /:hover/;
            for (var i=0; i<document.styleSheets.length; i++)
            {
                var sheet = document.styleSheets[i];
                for (var j=sheet.cssRules.length-1; j>=0; j--)
                {
                    var rule = sheet.cssRules[j];
                    if (rule.type === CSSRule.STYLE_RULE && ignore.test(rule.selectorText))
                    {
                        sheet.deleteRule(j);
                        count++;
                    }
                }
            }
        }
        catch(e){}
    }
}
/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}