
/*

 Textfields (with reset icon, adaptive placeholders etc)

 */

function textfield_init($o)
{
    if (typeof $o === "undefined") $o = $(".textfield");

    if (!$("body").hasClass("textfield-inited-globally")) {

        $(document).on("keydown keyup focus blur textfield-filled", ".textfield", function (e) {
            var is_filled = !!$(this).val();
            var $placeholder = $(this).nextAll(".textfield-placeholder").first();
            if (e.type === "textfield-filled") { // remove inline attribute value for CSS [value] selector purpose
                if ($(this).val().length) $(this).prop("value", $(this).val());
                $(this).removeAttr("value");
            }
            if ($placeholder.length && $placeholder.attr("data-label-short"))
            {
                if (e.type == "focusin" || e.type == "focusout")
                {
                    var label;
                    if (e.type == "focusin")
                    {
                        label = $placeholder.attr("data-label-short");
                        if (!$placeholder.attr("data-label-long"))
                        {
                            $placeholder.attr("data-label-long", $placeholder.html());
                        }
                    }
                    if (e.type == "focusout" && !is_filled)
                    {
                        if ($placeholder.attr("data-label-long"))
                        {
                            label = $placeholder.attr("data-label-long");
                        }
                    }
                    $placeholder.html(label);
                }
            }
            $(this).toggleClass("filled", is_filled);
        });

        $(".textfield").trigger("textfield-filled");
        $(document).ajaxStop(function () {
            $(".textfield").trigger("textfield-filled");
        });

        $("body").addClass("textfield-inited-globally")
    }

    $o.trigger("textfield-filled");

}
